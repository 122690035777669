.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background-color: #f9f9f9;
  font-family: Arial, sans-serif;
  padding: 20px;
  text-align: center;
}

h2 {
  font-size: 2rem;
  margin-bottom: 20px;
  color: #333;
}

.form-group input {
  width: 100%;
  max-width: 400px;
  padding: 10px;
  margin: 10px 0;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.button-container {
  display: flex;
  gap: 15px;
  margin-top: 20px;
}

button {
  padding: 10px 20px;
  font-size: 1rem;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.3s ease;
}

button:disabled {
  cursor: not-allowed;
  background-color: #ccc;
}

button:hover {
  opacity: 0.9;
}

.error-message {
  color: red;
  margin-top: 10px;
}

.success-message {
  color: green;
  margin-top: 10px;
}
