/* === common.css === */

/* === GLOBAL STYLES === */
body {
  font-family: Arial, sans-serif;
  font-size: 1rem;
  line-height: 1.5;
  color: #333;
  background-color: #f5f5f5;
  margin: 0;
  padding: 0;
}

.mobile-only {
  display: none;
}

/*
html, body {
  border: 5px solid orange;
}
*/




.header {
  margin-top: 0;
  padding-top: 0;
  font-size: 1.4rem;
  font-weight: bold;
  /*background: rgba(255, 0, 0, 0.1);

  border: 1px solid red; */
  
  line-height: 1; /* 👈 THIS is the final key */
}

.header1 {
  margin-top: 0;
  padding-top: 0;
  font-size: 1.4rem;
  font-weight: bold;
  margin-top: -250px; 
}





.info-button {
  margin-left: 0px;
  background: none;
  border: none;
  font-size: 0.75rem;
  color: #007bff;
  cursor: pointer;
  padding: 0;
  vertical-align: middle;
}

.info-button:hover {
  text-decoration: underline;
}

.campaign-footer {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-top: 8px;
}

.campaign-cost {
  font-size: 0.9rem;
  font-weight: 500;
  color: #333;
}


/* === LAYOUT === */
.container {
  max-width: 900px;
  margin: 0 auto;            /* Remove margin-top */
  padding: 20px;             /* Uniform padding */
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  /*border: 20px solid red;*/
}

.container-wide {
  max-width: 100%;
  margin: 0 auto;
  padding: 20px;
}










/* === TYPOGRAPHY === */
h1, h2, h3 {
  color: #333;
  margin-bottom: 10px;
}
label {
  font-weight: bold;
  color: #555;
}
input, select, textarea {
  padding: 10px;
  width: 100%;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-family: Arial, sans-serif;
}
input:focus, select:focus, textarea:focus {
  border-color: #007bff;
  outline: none;
}

/* === BUTTONS === */
.button {
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;
}
.button:hover {
  background-color: #0056b3;
}
.button-danger {
  background-color: #dc3545;
}
.button-danger:hover {
  background-color: #b02a37;
}
.button-success {
  background-color: #28a745;
}
.button-success:hover {
  background-color: #218838;
}
.puzzle-button {
  padding: 12px 20px;
  font-size: 1rem;
  border: 2px solid #000;
  border-radius: 25px;
  background: white;
  color: black;
  font-weight: 600;
  cursor: pointer;
  transition: background 0.2s ease;
}
.puzzle-button:hover {
  background: #f2f2f2;
}

/* === FORMS === */
.form-group-wrapper {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

/* === TABLES === */
.table-container {
  width: 100%;
  overflow-x: auto;
}
.styled-table {
  width: 100%;
  border-collapse: collapse;
}
.styled-table th,
.styled-table td {
  padding: 12px;
  text-align: left;
  border-bottom: 1px solid #ddd;
  white-space: nowrap;
}
.styled-table th {
  background-color: #f4f4f4;
  color: #333;
  font-weight: bold;
}
.styled-table tbody tr:hover {
  background-color: #f1f1f1;
}

/* === NAVBAR === */
.navbar {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 10;
  background-color: #ffffff;
  padding: 10px 15px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}


/* Top row with burger and title */
.navbar-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.navbar-title {
  font-size: 1.2rem;
  font-weight: bold;
  color: #333;
  margin-left: 10px;
}

/* Main nav list */
.nav-links {
  display: flex;
  list-style: none;
  margin: 10px 0 0;
  padding: 0;
  gap: 20px;
  flex-wrap: wrap;
}
.nav-links li a {
  text-decoration: none;
  color: #333;
  font-weight: 500;
  font-size: 1rem;
  padding: 6px 10px;
  border-radius: 4px;
  transition: background-color 0.2s ease;
}
.nav-links li a:hover {
  background-color: #f0f0f0;
}
.nav-links li a.active {
  color: #1abc9c;
  font-weight: bold;
}

/* Hamburger Button */
.hamburger {
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
  padding: 5px;
  color: #2c3e50;
}


/* === added for large desc pop up === */
.modal.large-modal {
  max-width: 95%;
  max-height: 90vh;
  overflow-y: auto;
  background: #fff;
  padding: 20px;
  border-radius: 10px;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5); /* dim background */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.modal {
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  width: 90%;
  max-width: 400px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  z-index: 1001;
  text-align: center;
}

.modal h3 {
  margin-top: 0;
}

.modal input[type="text"] {
  width: 100%;
  padding: 10px;
  font-size: 1rem;
  margin: 12px 0;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.modal button {
  width: 100%;
  padding: 10px;
  font-size: 1rem;
  margin-top: 8px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.modal button:first-of-type {
  background-color: #007bff;
  color: white;
}

.modal button:last-of-type {
  background-color: #ccc;
  color: #333;
}


/* === Submenu === */
/* Dropdown menu styles */
.dropdown {
  position: relative;
}


/* Submenu visibility */
.dropdown-menu {
  display: none;
  flex-direction: column;
  background-color: #f9f9f9; /* light submenu */
  border: 1px solid #ccc;
  padding: 10px 0;
}

.dropdown.open .dropdown-menu {
  display: flex;
}

.dropdown-menu {
  background-color: #f9f9f9;
  padding-left: 20px;
  margin-top: 5px;
}

.dropdown-toggle {
  cursor: pointer;
  font-weight: bold;
  color: #333;
  padding: 6px 10px;
}

.dropdown-menu li a {
  color: #333;
  font-size: 0.95rem;
  padding: 6px 0;
  display: block;
}









/* === MOBILE RESPONSIVE OVERRIDES === */
@media (max-width: 768px) {

  .desktop-only {
    display: none;
  }

  .mobile-only {
    display: block;
  }


.radio-wrapper {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  padding-top: 0px;
  margin-right: 0;
  min-width: 0;
}

.radio-wrapper input[type="radio"] {
  transform: scale(1.2);
  position: relative;
  top: -1px; /* 👈 positive = down, negative = up */
}



.campaign-card {
  display: block;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 12px;
  margin-bottom: 12px;
}

.campaign-card input[type="radio"] {
  transform: scale(1.2);
  margin: 0;
}

.card-header {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 4px;
}

.card-header input[type="radio"] {
  transform: scale(1.2);
  margin: 2px 0 0 0; /* Top margin nudges it down */
  vertical-align: bottom; 
}

.campaign-title {
  font-weight: bold;
  font-size: 1.2rem;
  color: #333;
}


.campaign-description {
  font-size: 0.9rem;
  color: #555;
  margin-bottom: 8px;
  margin-left: 20px; /* aligns with campaign title */
  margin-right: 8px;
  text-align: left;
}

.campaign-footer {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-left: 28px; /* aligns with description/title */
  margin-right: 8px;
}



.campaign-cost {
  font-size: 0.7rem;
  font-weight: 500;
  color: #333;
}




















  /* above   */


  /* Hide traditional table headers */
  .styled-table thead,
  .styled-table th {
    display: none;
  }

  /* Make each row a block for stacked layout */
  .styled-table tr {
    display: block;
    margin-bottom: 15px;
    border: 1px solid #ddd;
    border-radius: 6px;
    background-color: #fff;
    padding: 12px;
  }

  /* Stack table cells vertically */
  .styled-table td {
    display: block;
    width: 100%;
    text-align: left;
    padding: 8px 0;
    font-size: 0.95rem;
    border: none;
    border-bottom: 1px solid #eee;
  }

  .styled-table td:last-child {
    border-bottom: none;
  }

  /* Label above each cell for context */
  .styled-table td::before {
    content: attr(data-label);
    display: block;
    font-weight: bold;
    color: #555;
    margin-bottom: 4px;
  }

  /* Improve "Select" radio button appearance */
  .styled-table td[data-label="Select"] {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
  }

  /* Scale radio button for easier tapping */
  .styled-table input[type="radio"] {
    transform: scale(1.2);
  }

  /* Adjust description block */
  .styled-table td[data-label="Name + Description"] {
    padding-top: 10px;
    padding-bottom: 5px;
  }

  /* Fix info icon alignment */
.info-button {
  margin-left: 0px;
  background: none;
  border: none;
  font-size: 0.75rem;
  color: #007bff;
  cursor: pointer;
  padding: 0;
  vertical-align: middle;
}

.info-button:hover {
  text-decoration: underline;
}


  /* Responsive nav still intact */
  .navbar-header {
    justify-content: flex-start;
    gap: 10px;
  }

.nav-links {
  display: none;
  flex-direction: column;
  background-color: #f5f5f5; /* Light background */
  padding: 10px;
  border-radius: 5px;
  margin-top: 10px;
  width: 100%;
}

.nav-links.show {
  display: flex;
}

.nav-links li {
  margin: 10px 0;
  padding: 0 15px;
}

.nav-links li a,
.dropdown-toggle {
  color: #333 !important; /* Force dark text */
  text-decoration: none;
  font-weight: 500;
  font-size: 1rem;
  padding: 6px 10px;
  border-radius: 4px;
  transition: background-color 0.2s ease;
}



  .hamburger {
    display: block;
    color: #2c3e50;
    background-color: transparent;
  }

  /* Clean alignment of radio + label on small screens */
  .mobile-radio-group {
    display: flex;
    align-items: center;
    padding: 8px 0;
  }

  .mobile-radio-label {
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 1rem;
    font-weight: normal;
  }

  .mobile-radio-label input[type="radio"] {
    transform: scale(1.2);
  }








}



