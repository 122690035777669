.main-startup-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background-color: #f7f9fc; /* Light background color */
  font-family: Arial, sans-serif;
  text-align: center;
  padding: 20px;
}

.logo-container {
  margin-bottom: 20px;
}

.logo {
  width: 150px; /* Adjust the logo size */
  height: auto;
}

h1 {
  font-size: 2.5rem;
  margin: 20px 0;
  color: #333;
}

p {
  font-size: 1rem;
  margin-bottom: 30px;
  color: #555;
}

.button-container {
  display: flex;
  gap: 15px;
}

.btn {
  font-size: 1rem;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.btn-login {
  background-color: #4caf50; /* Green for Login */
  color: white;
}

.btn-login:hover {
  background-color: #45a049;
}

.btn-enroll {
  background-color: #2196f3; /* Blue for Sign Up */
  color: white;
}

.btn-enroll:hover {
  background-color: #1e88e5;
}
