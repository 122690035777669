.container {
  padding: 20px;
  max-width: 1000px;
  margin: 0 auto;
}

h2 {
  margin-bottom: 15px;
  text-align: center;
  font-size: 24px;
  color: #333;
}

.results-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

.results-table th,
.results-table td {
  padding: 10px;
  border: 1px solid #ddd;
  text-align: left;
}

.results-table th {
  background-color: #f4f4f4;
  font-weight: bold;
}

.results-table tr:hover {
  background-color: #f9f9f9;
}

.error {
  color: red;
  margin-top: 10px;
  text-align: center;
}

.loading {
  font-size: 16px;
  font-weight: bold;
  color: #555;
  text-align: center;
}

a {
  color: #007BFF;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}
