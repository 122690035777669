body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  background-color: #f9f9f9;
}
/*
.navbar {
  background-color: #007bff;
  padding: 10px 20px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
*/

.navbar {
  background-color: #ffffff;
  color: #333;
}



.container {
  padding: 20px;
}

h2 {
  text-align: center;
  margin-bottom: 20px;
}

.campaign-list {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
}

.campaign-card {
  background: white;
  padding: 15px;
  border-radius: 5px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  width: 300px;
  text-align: center;
}

.button-group button {
  margin: 5px;
  padding: 10px 15px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  background-color: #007bff;
  color: white;
}

.button-group button:hover {
  background-color: #0056b3;
}

.result-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

.result-table th,
.result-table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: center;
}

.result-table th {
  background-color: #007bff;
  color: white;
}
