/* General Container */
.container {
  max-width: 900px;
  margin: 20px auto;
  padding: 20px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

/* Header Section */
.header {
  text-align: center;
  margin-bottom: 20px;
  font-size: 1.5rem;
  font-weight: bold;
  color: #333;
}

/* Card Component */
.card {
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #f9f9f9;
  margin-bottom: 20px;
}

/* Form Group Layout */
.form-group-wrapper {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

label {
  font-weight: bold;
  color: #555;
}

input {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 1rem;
  width: 100%;
}

input:focus {
  border-color: #007bff;
  outline: none;
}

/* Button Styling */
.button {
  margin-top: 10px;
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 1rem;
  cursor: pointer;
  text-align: center;
}

.button:hover {
  background-color: #0056b3;
}

/* Error Message */
.error-message {
  color: #d9534f;
  font-weight: bold;
  text-align: center;
  margin-bottom: 15px;
}

/* Table Styling */
.table-container {
  width: 100%;
  overflow-x: auto;
  margin-top: 10px;
}

/* Campaign Catalog Table */
.styled-table {
  width: 100%;
  border-collapse: collapse;
  background-color: #fff;
}

.styled-table th, .styled-table td {
  padding: 12px;
  text-align: left;
  border-bottom: 1px solid #ddd;
  white-space: nowrap; /* Prevents text from wrapping */
}

.styled-table th {
  background-color: #f4f4f4;
  font-weight: bold;
  color: #333;
}

.styled-table td {
  font-size: 1rem;
  color: #444;
}

.styled-table input[type="radio"] {
  width: 20px;
  height: 20px;
  cursor: pointer;
  margin-right: 10px;
}

/* Ensure radio buttons are centered in the column */
.styled-table td:first-child {
  text-align: center;
}

/* Hover effect */
.styled-table tbody tr:hover {
  background-color: #f1f1f1;
}


/* Big Question List Leader Edit Table */
.edit-options-container {
  background: #ffffff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
  max-width: 400px;
  margin: 20px auto;
  text-align: left;
}

.edit-options-container label {
  display: block;
  font-weight: bold;
  margin-top: 10px;
}

.edit-options-container select,
.edit-options-container input {
  width: 100%;
  padding: 8px;
  margin-top: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
}

.button-group {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.save-button {
  background: #28a745;
  color: white;
  padding: 10px 15px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.cancel-button {
  background: #dc3545;
  color: white;
  padding: 10px 15px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.save-button:hover {
  background: #218838;
}

.cancel-button:hover {
  background: #c82333;
}

/* For List Leader read only */
.read-only-fields div {
  display: flex;
  gap: 15px;
  flex-wrap: wrap; /* Wraps text if too long */
}
.read-only-fields p {
  margin: 0; /* Remove default margin */
  white-space: nowrap; /* Prevent breaking into multiple lines */
}





/* Make table scrollable on smaller screens */
@media (max-width: 768px) {
  .styled-table th {
    display: none;
  }

  .styled-table tr {
    display: block;
    border: 1px solid #ddd;
    margin-bottom: 10px;
  }

  .styled-table td {
    display: block;
    text-align: right;
    position: relative;
    padding-left: 50%;
  }

  .styled-table td::before {
    content: attr(data-label);
    position: absolute;
    left: 10px;
    font-weight: bold;
    text-align: left;
  }

  .styled-table input[type="radio"] {
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
  }

/* Added for Big Question List Leader Options Edit */
  .edit-options-container {
    max-width: 90%;
    padding: 15px;
  }

  .button-group {
    flex-direction: column;
  }

  .save-button, .cancel-button {
    width: 100%;
    margin-top: 10px;
  }




}








/* Dropdown & Label Fix */
.dropdown-container {
  display: flex;
  align-items: center;
  gap: 10px; /* Adjust spacing */
  margin-bottom: 15px;
}

.dropdown-container label {
  font-size: 1.2rem;
  font-weight: bold;
  color: #333;
  white-space: nowrap;
}

.dropdown-container select {
  font-size: 1rem;
  padding: 8px 12px;
  border: 1px solid #007bff;
  border-radius: 4px;
  background-color: #fff;
  min-width: 200px;
  cursor: pointer;
}


/* Customer Table */
.customer-form-table {
  border-collapse: collapse;
  width: 100%;
  max-width: 600px;
}

.customer-form-table td {
  padding: 8px 10px;
  vertical-align: top;
}

.customer-form-table input {
  width: 100%;
  padding: 6px;
  box-sizing: border-box;
}

