.container {
  padding: 20px;
}

h2 {
  margin-bottom: 15px;
}

.campaign-list {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
}

.campaign-card {
  padding: 15px;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #f9f9f9;
  width: 250px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.campaign-card h3 {
  margin-bottom: 10px;
}

.button-group button {
  margin-right: 8px;
  padding: 8px 12px;
  cursor: pointer;
}

button:hover {
  background-color: #f1f1f1;
}
