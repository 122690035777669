.container {
  padding: 20px;
}

h2, h3 {
  margin-bottom: 10px;
}

.dropdown-container {
  margin-bottom: 20px;
}

.dropdown-container label {
  margin-right: 10px;
}

.items-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

.items-table th, .items-table td {
  padding: 10px;
  border: 1px solid #ddd;
  text-align: left;
}

.items-table th {
  background-color: #f4f4f4;
}

.items-table tr:hover {
  background-color: #f1f1f1;
}

.form-container {
  margin-top: 20px;
}

.form-container input {
  margin-right: 10px;
  padding: 8px;
}

button {
  padding: 8px 12px;
  cursor: pointer;
}

button:hover {
  background-color: #e0e0e0;
}

.error {
  color: red;
  margin-top: 10px;
}
