/* CustomerManagerPage.css */

/* Container styling */
.container {
  padding: 20px;
  font-family: Arial, sans-serif;
}

/* Table styling */
.data-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

.data-table th,
.data-table td {
  padding: 10px;
  border: 1px solid #ddd;
  text-align: left;
}

.data-table th {
  background-color: #f4f4f4;
}

/* Form styling */
.form-group {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
}

.form-group input {
  margin-bottom: 10px;
  padding: 10px;
  font-size: 16px;
}

.form-group button {
  padding: 10px;
  font-size: 16px;
  cursor: pointer;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
}

.form-group button:hover {
  background-color: #0056b3;
}
